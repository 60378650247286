var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isOrderFormLoading || _vm.isDeliveredProductTableLoading,
          expression: "isOrderFormLoading || isDeliveredProductTableLoading",
        },
      ],
      staticClass: "div-form-main order-form",
    },
    [
      _c("div", [
        !_vm.isOnline
          ? _c("div", [_c("h1", [_vm._v("Order Confirmation")])])
          : _vm._e(),
        _c(
          "div",
          {
            class: {
              "flex-left-column": _vm.isMobileScreen,
              "flex-between-row": !_vm.isMobileScreen,
            },
          },
          [
            _c(
              "div",
              { staticClass: "div-form-tab" },
              [
                !_vm.isOps &&
                _vm.orderForm.orderType === _vm.ORDER_TYPE.DELIVERED &&
                (_vm.isOnline ||
                  (!_vm.isOnline &&
                    _vm.tradeType === _vm.ORDER_TRADE_TYPE.PURCHASE))
                  ? _c(
                      "el-tabs",
                      {
                        model: {
                          value: _vm.orderFormTabActive,
                          callback: function ($$v) {
                            _vm.orderFormTabActive = $$v
                          },
                          expression: "orderFormTabActive",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Order Information",
                            name: "ORDER_INFO",
                            disabled:
                              _vm.currentView ===
                              _vm.ORDER_VIEW.EDIT_ORDER_COMM,
                          },
                        }),
                        !_vm.isVictoryFeatsCusPO
                          ? _c("el-tab-pane", {
                              attrs: {
                                label: "Commission",
                                name: "COMMISSION",
                                disabled:
                                  _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES ||
                                  (_vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.SALES &&
                                    !_vm.orderForm.sellerOrderStatus) ||
                                  (_vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE &&
                                    !_vm.orderForm.buyerOrderStatus) ||
                                  (_vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE &&
                                    this.orderForm.offlineBuyerSeller ===
                                      "SELLER") ||
                                  _vm.currentView ===
                                    _vm.ORDER_VIEW.EDIT_ORDER ||
                                  _vm.isEditPurchaseOrder,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            !_vm.isOnline &&
            ((_vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES &&
              _vm.orderForm.sellerOrderStatus ===
                _vm.ORDER_STATUS.PENDING_SELLER_CONFIRMATION) ||
              (_vm.tradeType === _vm.ORDER_TRADE_TYPE.PURCHASE &&
                _vm.orderForm.buyerOrderStatus ===
                  _vm.ORDER_STATUS.PENDING_BUYER_CONFIRMATION))
              ? _c(
                  "div",
                  { staticClass: "div-offline-form-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          round: "",
                          type: "primary",
                          size: _vm.isMobileScreen ? "mini" : "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleConfirmOrder()
                          },
                        },
                      },
                      [_vm._v("Confirm Order")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isOrderFormLoading && _vm.isOnline
              ? _c(
                  "div",
                  {
                    class:
                      _vm.currentView === _vm.ORDER_VIEW.CREATE_ORDER ||
                      !_vm.isOrderSideBar
                        ? "div-form-main-btn div-create-btn"
                        : "div-form-main-btn div-edit-btn",
                  },
                  [
                    _vm.hasEditButton()
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              type: "primary",
                              size: _vm.isMobileScreen ? "mini" : "small",
                              disabled:
                                (_vm.orderForm.buyerOrderStatus ===
                                  _vm.ORDER_STATUS.CONFIRMED &&
                                  _vm.orderForm.sellerOrderStatus ===
                                    _vm.ORDER_STATUS
                                      .PENDING_CREDIT_AUTHORIZATION) ||
                                (_vm.tradeType ===
                                  _vm.ORDER_TRADE_TYPE.PURCHASE &&
                                  _vm.orderFormTabActive === "COMMISSION"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.orderFormTabActive === "ORDER_INFO"
                                  ? _vm.handleOrderViewChange("EDIT_ORDER")
                                  : _vm.handleOrderViewChange("EDIT_ORDER_COMM")
                              },
                            },
                          },
                          [_vm._v(" Edit ")]
                        )
                      : _vm._e(),
                    _vm.currentView === _vm.ORDER_VIEW.CREATE_ORDER ||
                    (_vm.currentView === _vm.ORDER_VIEW.EDIT_ORDER &&
                      (_vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                        ? _vm.orderForm.sellerOrderStatus ===
                          _vm.ORDER_STATUS.DRAFT
                        : _vm.orderForm.buyerOrderStatus ===
                          _vm.ORDER_STATUS.DRAFT))
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              type: "danger",
                              size: _vm.isMobileScreen ? "mini" : "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSubmitOrder(true)
                              },
                            },
                          },
                          [_vm._v(" Save as Draft ")]
                        )
                      : _vm._e(),
                    _vm.currentView === _vm.ORDER_VIEW.CREATE_ORDER ||
                    _vm.currentView === _vm.ORDER_VIEW.EDIT_ORDER ||
                    _vm.isEditPurchaseOrder
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              type: "primary",
                              size: _vm.isMobileScreen ? "mini" : "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSubmitOrder(false)
                              },
                            },
                          },
                          [_vm._v(" Submit ")]
                        )
                      : _vm._e(),
                    _vm.currentView === _vm.ORDER_VIEW.EDIT_ORDER_COMM
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              type: "primary",
                              size: _vm.isMobileScreen ? "mini" : "small",
                            },
                            on: { click: _vm.submitOrderComm },
                          },
                          [_vm._v(" Submit ")]
                        )
                      : _vm._e(),
                    _vm.currentView === _vm.ORDER_VIEW.EDIT_ORDER ||
                    _vm.currentView === _vm.ORDER_VIEW.EDIT_ORDER_COMM ||
                    _vm.isEditPurchaseOrder
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              type: "primary",
                              size: _vm.isMobileScreen ? "mini" : "small",
                            },
                            on: { click: _vm.handleCancelEditClick },
                          },
                          [_vm._v(" Cancel Edit ")]
                        )
                      : _vm._e(),
                    !_vm.isEditPurchaseOrder &&
                    !_vm.isOps &&
                    !_vm.isViewOnly &&
                    _vm.currentView !== _vm.ORDER_VIEW.EDIT_ORDER &&
                    _vm.currentView !== _vm.ORDER_VIEW.EDIT_ORDER_COMM
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              round: "",
                              outline: "",
                              size: _vm.isMobileScreen ? "mini" : "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCloseOrderForm("CLOSE")
                              },
                            },
                          },
                          [_vm._v(" Close ")]
                        )
                      : _vm._e(),
                    !_vm.isViewOnly &&
                    !_vm.isOps &&
                    _vm.currentView !== _vm.ORDER_VIEW.CREATE_ORDER &&
                    (_vm.hasConfirmDropdownItem() ||
                      _vm.hasCancelDropdownItem() ||
                      _vm.hasDeleteDropdownItem() ||
                      _vm.hasVerifyDropdownItem())
                      ? _c(
                          "el-dropdown",
                          {
                            attrs: { trigger: "click" },
                            on: { command: _vm.handleOrderFormDropdownClick },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "el-dropdown-more-btn",
                                attrs: {
                                  size: _vm.isMobileScreen ? "mini" : "small",
                                  round: "",
                                },
                              },
                              [_c("em", { staticClass: "el-icon-more" })]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.hasConfirmDropdownItem()
                                  ? _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "CONFIRM" } },
                                      [_vm._v(" Confirm Order ")]
                                    )
                                  : _vm._e(),
                                _vm.hasCancelDropdownItem()
                                  ? _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "CANCEL" } },
                                      [_vm._v(" Cancel Order ")]
                                    )
                                  : _vm._e(),
                                _vm.hasDeleteDropdownItem()
                                  ? _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "DELETE" } },
                                      [_vm._v(" Delete Order ")]
                                    )
                                  : _vm._e(),
                                _vm.hasVerifyDropdownItem()
                                  ? _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "VERIFY" } },
                                      [_vm._v(" Verify Order ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      !_vm.isOrderFormLoading
        ? _c(
            "div",
            {
              class:
                _vm.currentView === _vm.ORDER_VIEW.CREATE_ORDER ||
                !_vm.isOrderSideBar
                  ? "div-form-main-container div-create-container"
                  : "div-form-main-container",
            },
            [
              _vm.orderFormTabActive === "ORDER_INFO"
                ? _c(
                    "el-form",
                    {
                      ref: "orderForm",
                      attrs: {
                        model: _vm.orderForm,
                        "validate-on-rule-change": false,
                        "label-position": "top",
                      },
                    },
                    [
                      _vm.isOrderTopBar
                        ? _c("div", [
                            _c(
                              "div",
                              { staticStyle: { border: "1px solid #E4E4E4" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "div-top-summary-order-no" },
                                  [
                                    _c("h3", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tradeType ===
                                            _vm.ORDER_TRADE_TYPE.SALES
                                            ? "Sales Order No:"
                                            : "Purchase Order No:"
                                        )
                                      ),
                                    ]),
                                    _c("h3", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tradeType ===
                                            _vm.ORDER_TRADE_TYPE.SALES
                                            ? _vm.orderForm.salesOrderNo
                                            : _vm.orderForm.purchaseOrderNo
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "div-top-summary-order-status",
                                  },
                                  [
                                    _c("h3", [_vm._v("Order Status:")]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "status-tag",
                                        style: _vm.getStatusTagStyle(
                                          _vm.orderForm
                                            .sellerSysOrganizationId ===
                                            _vm.myCompany.id
                                            ? _vm.orderForm.sellerOrderStatus
                                            : _vm.orderForm.buyerOrderStatus
                                        ),
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderForm
                                              .sellerSysOrganizationId ===
                                              _vm.myCompany.id
                                              ? _vm.capitalizeFirstLetter(
                                                  _vm.orderForm
                                                    .sellerOrderStatus,
                                                  "_"
                                                )
                                              : _vm.capitalizeFirstLetter(
                                                  _vm.orderForm
                                                    .buyerOrderStatus,
                                                  "_"
                                                )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      !_vm.isOps &&
                      _vm.currentView !== _vm.ORDER_VIEW.CREATE_ORDER
                        ? _c(
                            "div",
                            { staticClass: "basic-card-block margin-top-0" },
                            [
                              _c("h1", [_vm._v("Delivered Information")]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.deliveredProductTableData,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Product Name",
                                          prop: "productName",
                                          "show-overflow-tooltip": "",
                                          "min-width": "200px",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Product Specs",
                                          prop: "specificationName",
                                          "show-overflow-tooltip": "",
                                          "min-width": "200px",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Barge Name",
                                          prop: "bargeName",
                                          "show-overflow-tooltip": "",
                                          "min-width": "200px",
                                        },
                                      }),
                                      _vm.orderForm.orderType !==
                                      _vm.ORDER_TYPE.EX_WHARF
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "BDN Document",
                                              "min-width": "200px",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row.docNumber
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFile(
                                                                      scope.row
                                                                        .bdnPdfId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .docNumber
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _c("p", [
                                                            _vm._v("-"),
                                                          ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3911782450
                                            ),
                                          })
                                        : _c("el-table-column", {
                                            attrs: {
                                              label: "Loading Documents",
                                              width: "200",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        [
                                                          scope.row.cqPdfId
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.previewFile(
                                                                          scope
                                                                            .row
                                                                            .cqPdfId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      `[${scope.row.docNumber}] CQ File`
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          scope.row.coqPdfId
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    margin: "0",
                                                                  },
                                                                  attrs: {
                                                                    type: "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.previewFile(
                                                                          scope
                                                                            .row
                                                                            .coqPdfId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      `[${scope.row.docNumber}] COQ File`
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !scope.row.cqPdfId &&
                                                          !scope.row.coqPdfId
                                                            ? _c("p", [
                                                                _vm._v("-"),
                                                              ])
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              735789695
                                            ),
                                          }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "eBDN Date",
                                          "show-overflow-tooltip": "",
                                          "min-width": "200px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.docDate
                                                          ? _vm
                                                              .$moment(
                                                                scope.row
                                                                  .docDate
                                                              )
                                                              .format(
                                                                "DD-MM-YYYY"
                                                              )
                                                          : "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3313149260
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Delivered Quantity",
                                          "show-overflow-tooltip": "",
                                          "min-width": "200px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.docQty
                                                          ? _vm.numberWithCommas(
                                                              scope.row.docQty,
                                                              3
                                                            )
                                                          : "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3931036628
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "ETC",
                                          "show-overflow-tooltip": "",
                                          "min-width": "200px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        scope.row.etc
                                                          ? _vm
                                                              .$moment(
                                                                scope.row.etc
                                                              )
                                                              .format(
                                                                "DD-MM-YYYY"
                                                              )
                                                          : "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3309116236
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isShowVictoryFeats
                        ? [
                            _c(
                              "div",
                              { staticClass: "basic-card-block margin-top-0" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-between-row",
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      width: "400px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-between-column" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "sub-title-text",
                                            staticStyle: {
                                              "text-align": "left",
                                              "font-weight": "400",
                                              "line-height": "32px",
                                            },
                                          },
                                          [_vm._v("Credit Limit ")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "sub-title-text",
                                            staticStyle: {
                                              "text-align": "left",
                                              "font-size": "20px",
                                              "font-weight": "bolder",
                                              "margin-top": "4px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "SGD " +
                                                  _vm._s(_vm.creditLimit ?? "-")
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex-between-column" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "sub-title-text pricing-date-picker",
                                            staticStyle: {
                                              "text-align": "left",
                                              "font-weight": "400",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.canEditVictoryFields,
                                                    placeholder:
                                                      "Pick Your Pricing Date",
                                                    type: "date",
                                                    format: "dd-MM-yyyy",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.chooseDate(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm.pricingDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "pricingDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.pricingDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "sub-title-text",
                                            staticStyle: {
                                              "text-align": "left",
                                              "font-size": "20px",
                                              "font-weight": "bolder",
                                              "margin-top": "-10px",
                                              "padding-left": "10px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "SGD " +
                                                  _vm._s(
                                                    _vm.todayPricing ?? "-"
                                                  )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("h1", [_vm._v("Summary Form")]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "div-frac-13-avg": !_vm.isMobileScreen,
                                        "div-frac-1": _vm.isMobileScreen,
                                      },
                                    },
                                    [
                                      _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE
                                        ? [
                                            _vm.isOnline
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Company Name (Seller)",
                                                      prop: "sellerSysOrganizationId",
                                                      rules: _vm.rules.reqRule,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          filterable: "",
                                                          "value-key": "id",
                                                          disabled:
                                                            _vm.currentView ===
                                                              _vm.ORDER_VIEW
                                                                .VIEW_ORDER ||
                                                            _vm.currentView ===
                                                              _vm.ORDER_VIEW
                                                                .EDIT_ORDER ||
                                                            _vm.linkedPurchaseTermContract !==
                                                              null,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleCompanyNameSelect(
                                                              $event,
                                                              "SELLER",
                                                              true
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderForm
                                                              .sellerSysOrganizationId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderForm,
                                                              "sellerSysOrganizationId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderForm.sellerSysOrganizationId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.creditAssociationList,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.id,
                                                              attrs: {
                                                                label:
                                                                  item.counterpartyCompanyName,
                                                                value:
                                                                  item.counterpartySysOrganizationId,
                                                                disabled:
                                                                  item.status !==
                                                                  "ACTIVE",
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Company Name (Seller)",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderForm
                                                            .sellerCompanyName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderForm,
                                                            "sellerCompanyName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderForm.sellerCompanyName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        : [
                                            _vm.isOnline
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Company Name (Buyer)",
                                                      prop: "buyerSysOrganizationId",
                                                      rules: _vm.rules.reqRule,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          filterable: "",
                                                          "value-key": "id",
                                                          disabled:
                                                            _vm.currentView ===
                                                              _vm.ORDER_VIEW
                                                                .VIEW_ORDER ||
                                                            _vm.currentView ===
                                                              _vm.ORDER_VIEW
                                                                .EDIT_ORDER ||
                                                            _vm.linkedSalesTermContract !==
                                                              null,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleCompanyNameSelect(
                                                              $event,
                                                              "BUYER",
                                                              true
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderForm
                                                              .buyerCompanyName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderForm,
                                                              "buyerCompanyName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderForm.buyerCompanyName",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.creditAssociationList,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.id,
                                                              attrs: {
                                                                label:
                                                                  item.counterpartyCompanyName,
                                                                value:
                                                                  item.counterpartySysOrganizationId,
                                                                disabled:
                                                                  item.status !==
                                                                  "ACTIVE",
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Company Name (Buyer)",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.orderForm
                                                            .buyerCompanyName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.orderForm,
                                                            "buyerCompanyName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "orderForm.buyerCompanyName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ],
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Territory",
                                            prop: "territory",
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                "value-key": "id",
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              on: {
                                                change:
                                                  _vm.clearLocationAndBunkerBarge,
                                              },
                                              model: {
                                                value: _vm.orderForm.territory,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "territory",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderForm.territory",
                                              },
                                            },
                                            _vm._l(
                                              [
                                                {
                                                  label:
                                                    "Self-Collect at Kiosk",
                                                  value: 1,
                                                },
                                                {
                                                  label:
                                                    "Bunker Delivery Service",
                                                  value: 2,
                                                },
                                              ],
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Order Date",
                                            prop: "orderDate",
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _vm.isVictoryFeatsCusPO
                                            ? _c("el-date-picker", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEditVictoryFields,
                                                  type: "datetime",
                                                  format:
                                                    _vm.DATETIME_FORMAT
                                                      .GEO_DATETIME,
                                                },
                                                on: {
                                                  change: (val) => {
                                                    _vm.orderForm.nominationDate =
                                                      null
                                                    _vm.handleChangeOrderDateViaSummary(
                                                      val
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm.orderDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "orderDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.orderDate",
                                                },
                                              })
                                            : _c("el-date-picker", {
                                                attrs: {
                                                  type: "datetime",
                                                  format:
                                                    _vm.DATETIME_FORMAT
                                                      .GEO_DATETIME,
                                                  disabled:
                                                    _vm.currentView ===
                                                    _vm.ORDER_VIEW.VIEW_ORDER,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.orderForm.nominationDate =
                                                      null
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm.orderDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "orderDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.orderDate",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      !_vm.isVesselTBC &&
                                      !_vm.isVictoryFeatsCusPO
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "Vessel" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "filter-method": (val) =>
                                                      _vm.handleSearchMpaReceivingVessels(
                                                        val
                                                      ),
                                                    filterable: "",
                                                    "popper-class":
                                                      "custom-ship-selector",
                                                    disabled:
                                                      !_vm.canEditVictoryFields,
                                                    placeholder: "Select",
                                                  },
                                                  on: {
                                                    "visible-change": function (
                                                      $event
                                                    ) {
                                                      return _vm.searchVesselvisibleChange(
                                                        $event
                                                      )
                                                    },
                                                    change: function ($event) {
                                                      return _vm.handleSelectVessel(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.vesselSearchKey,
                                                    callback: function ($$v) {
                                                      _vm.vesselSearchKey = $$v
                                                    },
                                                    expression:
                                                      "vesselSearchKey",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "200px",
                                                        "font-size": "14px",
                                                        "font-weight": "500",
                                                        "margin-left": "20px",
                                                        "margin-bottom": "10px",
                                                      },
                                                    },
                                                    [_vm._v("Name")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "100px",
                                                        "font-size": "14px",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [_vm._v("IMO")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        "font-size": "14px",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [_vm._v("Licence")]
                                                  ),
                                                  _vm._l(
                                                    _vm.searchedVesselList ||
                                                      [],
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.id,
                                                          attrs: {
                                                            label:
                                                              item.shipName,
                                                            value: item.id,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                width: "200px",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.shipName
                                                                ) + " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                width: "100px",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(item.imo)
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.licenceNo
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Bunker Location",
                                            prop: "locationLevel",
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _vm.isVictoryFeatsSelfCollectKiosk
                                            ? _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.canEditVictoryFields,
                                                  },
                                                  on: {
                                                    "visible-change":
                                                      _vm.handleLocationOption,
                                                    change:
                                                      _vm.handleChangeCollectKioskLocation,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .bunkerLocation,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "bunkerLocation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.bunkerLocation",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selfCollectKioskLocationOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            : _c("el-cascader", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEditVictoryFields,
                                                  clearable: "",
                                                  "popper-class":
                                                    "el-cascader-long",
                                                  options: _vm.locationOptions,
                                                  props:
                                                    _vm.locationOptionProps,
                                                },
                                                on: {
                                                  "visible-change":
                                                    _vm.handleLocationOption,
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm.locationLevel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "locationLevel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.locationLevel",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Bunker Barge",
                                            prop: "bunkerBargeName",
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _vm.isVictoryFeatsSelfCollectKiosk
                                            ? _c("el-input", {
                                                attrs: { disabled: true },
                                                model: {
                                                  value:
                                                    _vm.orderForm
                                                      .bunkerBargeName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "bunkerBargeName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.bunkerBargeName",
                                                },
                                              })
                                            : _c("barge-or-vessel-selector", {
                                                attrs: {
                                                  schedulFormData:
                                                    _vm.orderForm,
                                                  availableBargesLoading:
                                                    _vm.availableBargesLoading,
                                                  availableBargesOptions:
                                                    _vm.availableBargesOptions,
                                                  disabled:
                                                    !_vm.canEditVictoryFields,
                                                },
                                                on: {
                                                  handleChangeBarge:
                                                    _vm.handleChangeBarge,
                                                  visibleChange:
                                                    _vm.visibleChange,
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Next Port",
                                            prop: "nextPort",
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.nextPortDisabled ||
                                                !_vm.canEditVictoryFields,
                                            },
                                            on: {
                                              input: _vm.handleInputNextPort,
                                            },
                                            model: {
                                              value: _vm.orderForm.nextPort,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm,
                                                  "nextPort",
                                                  $$v
                                                )
                                              },
                                              expression: "orderForm.nextPort",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "div-frac-13-avg": !_vm.isMobileScreen,
                                        "div-frac-1": _vm.isMobileScreen,
                                      },
                                    },
                                    [
                                      _vm.isVictoryFeatsCusPO
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Product Name",
                                                prop: `purchaseProducts.0.sysProductId`,
                                                rules: _vm.rules.reqRule,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isVictoryFeatsAdhocPO ||
                                                      _vm.currentView ===
                                                        _vm.ORDER_VIEW
                                                          .VIEW_ORDER ||
                                                      !(
                                                        _vm.orderForm
                                                          .purchaseProducts[0]
                                                          .status ===
                                                          "PENDING" ||
                                                        !_vm.orderForm
                                                          .purchaseProducts[0]
                                                          .status
                                                      ),
                                                  },
                                                  on: {
                                                    "visible-change": function (
                                                      $event
                                                    ) {
                                                      return _vm.handleProductNameVisibleChange(
                                                        "BUYER"
                                                      )
                                                    },
                                                    change: function ($event) {
                                                      return _vm.handleProductNameChange(
                                                        $event,
                                                        0,
                                                        "BUYER"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .purchaseProducts[0]
                                                        .sysProductId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm
                                                          .purchaseProducts[0],
                                                        "sysProductId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.purchaseProducts[0].sysProductId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.productList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isVictoryFeatsCusPO
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticClass: "nominatedQty",
                                              attrs: {
                                                label: "Nominated Qty",
                                                prop: `purchaseProducts.0.finalQuantity`,
                                                rules:
                                                  _vm.rules.vistoryFinalQtyRule,
                                              },
                                            },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEditVictoryFields,
                                                  controls: false,
                                                  min: null,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.handleFinalQtyChange(
                                                      0,
                                                      "BUYER"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm
                                                      .purchaseProducts[0]
                                                      .finalQuantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm
                                                        .purchaseProducts[0],
                                                      "finalQuantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.purchaseProducts[0].finalQuantity",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isVictoryFeatsCusPO
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Unit Metric",
                                                prop: `purchaseProducts.0.unitMetric`,
                                                rules: _vm.rules.notReqRule,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.canEditVictoryFields,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .purchaseProducts[0]
                                                        .unitMetric,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm
                                                          .purchaseProducts[0],
                                                        "unitMetric",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.purchaseProducts[0].unitMetric",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.UNIT_OPTIONS,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isVictoryFeatsCusPO
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Nomination Date",
                                                prop: "nominationDate",
                                                rules:
                                                  _vm.rules
                                                    .validateNominationDate,
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEditVictoryFields,
                                                  type: "datetime",
                                                  format:
                                                    _vm.DATETIME_FORMAT
                                                      .GEO_DATETIME,
                                                  "picker-options":
                                                    _vm.nominationDatePickerOption(),
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleNominationDateChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm
                                                      .nominationDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "nominationDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.nominationDate",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.tradeType ===
                                        _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                        _vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE
                                            .PURCHASE_AND_SALES) &&
                                      _vm.isVictoryFeatsCusPO
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Purchase Reference No.",
                                                prop: "buyerReferenceNo",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEditVictoryFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm
                                                      .buyerReferenceNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "buyerReferenceNo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.buyerReferenceNo",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "div-frac-1" },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Remark" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 2,
                                              disabled:
                                                !_vm.canEditVictoryFields,
                                            },
                                            model: {
                                              value: _vm.orderForm.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "orderForm.remark",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      !_vm.isShowVictoryFeats &&
                      _vm.tradeType === _vm.ORDER_TRADE_TYPE.PURCHASE
                        ? _c(
                            "div",
                            {
                              class: {
                                "div-frac-1": _vm.isMobileScreen,
                                "div-frac-13-avg": !_vm.isMobileScreen,
                              },
                            },
                            [
                              _vm.isOnline
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Company Name (Seller)",
                                        prop: "sellerSysOrganizationId",
                                        rules: _vm.rules.reqRule,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            "value-key": "id",
                                            disabled:
                                              _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER ||
                                              _vm.currentView ===
                                                _vm.ORDER_VIEW.EDIT_ORDER ||
                                              _vm.linkedPurchaseTermContract !==
                                                null,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleCompanyNameSelect(
                                                $event,
                                                "SELLER",
                                                true
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.orderForm
                                                .sellerSysOrganizationId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderForm,
                                                "sellerSysOrganizationId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "orderForm.sellerSysOrganizationId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.creditAssociationList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label:
                                                  item.counterpartyCompanyName,
                                                value:
                                                  item.counterpartySysOrganizationId,
                                                disabled:
                                                  item.status !== "ACTIVE",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Company Name (Seller)" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.orderForm.sellerCompanyName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderForm,
                                              "sellerCompanyName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderForm.sellerCompanyName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isVictoryFeatsCusPO
                        ? _c(
                            "div",
                            {
                              ref: "productPriceDiv",
                              staticClass: "basic-card-block",
                            },
                            [
                              _c("h1", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isOps
                                        ? "Product Information"
                                        : "Product and Pricing Information"
                                    )
                                ),
                              ]),
                              _c("div", { staticClass: "product-and-ricing" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-product-other-costs-info-body",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-between-row margin-bottom-5",
                                        staticStyle: {
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE
                                            .PURCHASE_AND_SALES
                                            ? _c("h4", [
                                                _vm._v(
                                                  "Purchase Product and Pricing Information"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.isShowVictoryFeats &&
                                          _vm.tradeType ===
                                            _vm.ORDER_TRADE_TYPE.PURCHASE
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#30619E",
                                                    "font-weight": "400",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "*Quantity conversion unit is for reference only"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                        ((_vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE.PURCHASE &&
                                          _vm.orderForm.orderType ===
                                            _vm.ORDER_TYPE.DELIVERED) ||
                                          (_vm.tradeType ===
                                            _vm.ORDER_TRADE_TYPE
                                              .PURCHASE_AND_SALES &&
                                            _vm.orderForm.orderType ===
                                              _vm.ORDER_TYPE.DELIVERED)) &&
                                        !_vm.isViewOnly
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "auto 8px",
                                                },
                                                attrs: {
                                                  type: "primary",
                                                  round: "",
                                                  size: "mini",
                                                  disabled:
                                                    _vm.currentView ===
                                                    _vm.ORDER_VIEW.VIEW_ORDER,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addProduct(
                                                      "BUYER"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Add ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                      ? _c(
                                          "el-table",
                                          {
                                            ref: "purchaseProductTable",
                                            attrs: {
                                              data: _vm.orderForm
                                                .purchaseProducts,
                                              border: "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "No.",
                                                width: "50px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "h5-el-table-col-index",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.$index + 1
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1010444001
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "300px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Product Name"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseProducts.${scope.$index}.sysProductId`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.isVictoryFeatsAdhocPO ||
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  "visible-change":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductNameVisibleChange(
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductNameChange(
                                                                        $event,
                                                                        scope.$index,
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .sysProductId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "sysProductId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.sysProductId",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.productList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.id,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1180603011
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Nominated Qty"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseProducts.${scope.$index}.finalQuantity`,
                                                              rules:
                                                                _vm.rules
                                                                  .finalQtyRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 3,
                                                                  min: null,
                                                                  disabled:
                                                                    (_vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER &&
                                                                      !_vm.isEditPurchaseOrder) ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleFinalQtyChange(
                                                                        scope.$index,
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .finalQuantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "finalQuantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.finalQuantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3394847670
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "200px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Specs"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseProducts.${scope.$index}.sysProductSpecificationId`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqRule
                                                                  : _vm.rules
                                                                      .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  "visible-change":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductSpecsVisibleChange(
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductSpecsChange(
                                                                        $event,
                                                                        scope.$index,
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .sysProductSpecificationId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "sysProductSpecificationId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.sysProductSpecificationId",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.productSpecificationList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.id,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                322209372
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Min Qty"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseProducts.${scope.$index}.minimumQuantity`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqmMinQtyRule
                                                                  : _vm.rules
                                                                      .minQtyRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 3,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleMinQtyChange(
                                                                        scope.$index,
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .minimumQuantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "minimumQuantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.minimumQuantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2015221312
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Max Qty"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseProducts.${scope.$index}.maximumQuantity`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqMaxQtyRule
                                                                  : _vm.rules
                                                                      .maxQtyRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 3,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleMaxQtyChange(
                                                                        scope.$index,
                                                                        "BUYER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .maximumQuantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "maximumQuantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.maximumQuantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                538637997
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "120px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Unit Metric"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseProducts.${scope.$index}.unitMetric`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqRule
                                                                  : _vm.rules
                                                                      .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ) ||
                                                                    !_vm.isShowVictoryFeats,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .unitMetric,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "unitMetric",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.unitMetric",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.UNIT_OPTIONS,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.value,
                                                                      attrs: {
                                                                        label:
                                                                          item.label,
                                                                        value:
                                                                          item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                127114307
                                              ),
                                            }),
                                            !(
                                              _vm.linkedPurchaseTermContract &&
                                              _vm.linkedPurchaseTermContract
                                                .termContractType ===
                                                "TIME_CHARTER"
                                            ) && !_vm.isOps
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    "min-width": "150px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v("* "),
                                                            ]),
                                                            _vm._v(
                                                              "Price (MT)"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  prop: `purchaseProducts.${scope.$index}.price`,
                                                                  rules:
                                                                    _vm.rules
                                                                      .reqRule,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    attrs: {
                                                                      controls: false,
                                                                      precision: 3,
                                                                      min: null,
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                          _vm
                                                                            .ORDER_VIEW
                                                                            .VIEW_ORDER ||
                                                                        !(
                                                                          scope
                                                                            .row
                                                                            .status ===
                                                                            "PENDING" ||
                                                                          !scope
                                                                            .row
                                                                            .status
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .price,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "price",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.price",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    843847719
                                                  ),
                                                })
                                              : _vm._e(),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Currency",
                                                "min-width": "120px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .currency,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "currency",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.currency",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.currencyList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.code,
                                                                        value:
                                                                          item.code,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2359304257
                                              ),
                                            }),
                                            _vm.orderForm.orderType !==
                                            _vm.ORDER_TYPE.EX_WHARF
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    "min-width": "250px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            !_vm.isShowVictoryFeats
                                                              ? _c("span", [
                                                                  _vm._v("* "),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "Physical Supplier BDN (Buyer)"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  prop: `purchaseProducts.${scope.$index}.physicalSupplierSysOrganizationId`,
                                                                  rules:
                                                                    _vm.isShowVictoryFeats
                                                                      ? _vm
                                                                          .rules
                                                                          .notReqRule
                                                                      : _vm
                                                                          .rules
                                                                          .reqRule,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      filterable:
                                                                        "",
                                                                      "value-key":
                                                                        "id",
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                          _vm
                                                                            .ORDER_VIEW
                                                                            .VIEW_ORDER ||
                                                                        !(
                                                                          scope
                                                                            .row
                                                                            .status ===
                                                                            "PENDING" ||
                                                                          !scope
                                                                            .row
                                                                            .status
                                                                        ) ||
                                                                        _vm.isShowVictoryFeats,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePhysicalSupplierBdnChange(
                                                                            $event,
                                                                            scope.$index,
                                                                            "BUYER"
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .physicalSupplierSysOrganizationId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "physicalSupplierSysOrganizationId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.physicalSupplierSysOrganizationId",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.physicalSupplierList,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.id,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.companyName,
                                                                              value:
                                                                                item.id,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1908893418
                                                  ),
                                                })
                                              : _vm._e(),
                                            _vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE
                                                .BARGING_NOMINATION &&
                                            !_vm.isShowVictoryFeats
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "Price Type",
                                                    "min-width": "120px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                          _vm
                                                                            .ORDER_VIEW
                                                                            .VIEW_ORDER ||
                                                                        !(
                                                                          scope
                                                                            .row
                                                                            .status ===
                                                                            "PENDING" ||
                                                                          !scope
                                                                            .row
                                                                            .status
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePriceTypeSelect(
                                                                            $event,
                                                                            scope.row,
                                                                            "BUYER",
                                                                            scope.$index
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .priceType,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "priceType",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.priceType",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.ORDER_PRICE_TYPE_OPTIONS,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.value,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.label,
                                                                              value:
                                                                                item.value,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2888914956
                                                  ),
                                                })
                                              : _vm._e(),
                                            !_vm.isOps &&
                                            _vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE.BARGING_NOMINATION
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    width:
                                                      _vm.orderForm
                                                        .orderType ===
                                                        _vm.ORDER_TYPE
                                                          .EX_WHARF &&
                                                      _vm.currentView !==
                                                        _vm.ORDER_VIEW
                                                          .CREATE_ORDER
                                                        ? "240px"
                                                        : "auto",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.orderForm.orderType ===
                                              _vm.ORDER_TYPE.DELIVERED ||
                                            (_vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE.DELIVERED &&
                                              _vm.orderForm.purchaseProducts[0]
                                                .status &&
                                              _vm.orderForm.purchaseProducts[0]
                                                .status !== "PENDING")
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "",
                                                    width: "50px",
                                                    fixed: "right",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              [
                                                                scope.row
                                                                  .status ===
                                                                  "PENDING" ||
                                                                !scope.row
                                                                  .status
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "danger",
                                                                          icon: "el-icon-delete",
                                                                          size: "mini",
                                                                          circle:
                                                                            "",
                                                                          disabled:
                                                                            _vm.currentView ===
                                                                              _vm
                                                                                .ORDER_VIEW
                                                                                .VIEW_ORDER ||
                                                                            _vm
                                                                              .orderForm
                                                                              .purchaseProducts
                                                                              .length ===
                                                                              1,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAlert(
                                                                                true,
                                                                                scope.row,
                                                                                scope.$index,
                                                                                "BUYER"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "el-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "item",
                                                                        attrs: {
                                                                          effect:
                                                                            "dark",
                                                                          content:
                                                                            "Verified",
                                                                          placement:
                                                                            "top",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-check",
                                                                            attrs:
                                                                              {
                                                                                type: "success",
                                                                                size: "mini",
                                                                                circle:
                                                                                  "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1472814109
                                                  ),
                                                })
                                              : _vm._e(),
                                            !_vm.isOps &&
                                            _vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE.BARGING_NOMINATION
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    type: "expand",
                                                    "min-width": "50px",
                                                    fixed: "right",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "table-col-product-pricing",
                                                              {
                                                                attrs: {
                                                                  parent:
                                                                    "OrderFormPurchaseProduct",
                                                                  nominationDate:
                                                                    new Date(
                                                                      _vm.orderForm.nominationDate
                                                                    ),
                                                                  scopeRow:
                                                                    scope.row,
                                                                  scopeIndex:
                                                                    scope.$index,
                                                                  priceProp:
                                                                    "purchaseProducts",
                                                                  priceType:
                                                                    scope.row
                                                                      .priceType,
                                                                  priceMethodologyList:
                                                                    _vm.priceMethodologyList,
                                                                  rulesReq:
                                                                    _vm.rules
                                                                      .reqRule,
                                                                  notReqRule:
                                                                    _vm.rules
                                                                      .notReqRule,
                                                                  holidayMap:
                                                                    _vm.holidayMap,
                                                                  resetProductPriceWatcher:
                                                                    _vm.resetProductPriceWatcher,
                                                                  isAllFieldsDisabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  handlePriceInfoChange:
                                                                    _vm.handlePriceInfoChange,
                                                                  handleReferencePriceChange:
                                                                    _vm.handleReferencePriceChange,
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3272983379
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-between-row margin-bottom-5",
                                        staticStyle: {
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "margin-top-10" },
                                          [
                                            _vm.tradeType ===
                                            _vm.ORDER_TRADE_TYPE
                                              .PURCHASE_AND_SALES
                                              ? _c("h4", [
                                                  _vm._v(
                                                    "Sales Product and Pricing Information"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        ((_vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE.SALES &&
                                          _vm.orderForm.orderType ===
                                            _vm.ORDER_TYPE.DELIVERED) ||
                                          (_vm.tradeType ===
                                            _vm.ORDER_TRADE_TYPE
                                              .PURCHASE_AND_SALES &&
                                            _vm.orderForm.orderType ===
                                              _vm.ORDER_TYPE.DELIVERED)) &&
                                        !_vm.isViewOnly
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "auto 8px",
                                                },
                                                attrs: {
                                                  type: "primary",
                                                  round: "",
                                                  size: "mini",
                                                  disabled:
                                                    _vm.currentView ===
                                                    _vm.ORDER_VIEW.VIEW_ORDER,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addProduct(
                                                      "SELLER"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Add ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.SALES ||
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                      ? _c(
                                          "el-table",
                                          {
                                            ref: "salesProductTable",
                                            attrs: {
                                              data: _vm.orderForm.salesProducts,
                                              border: "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "No.",
                                                width: "50px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "h5-el-table-col-index",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.$index + 1
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1010444001
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "300px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Product Name"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesProducts.${scope.$index}.sysProductId`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  "visible-change":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductNameVisibleChange(
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductNameChange(
                                                                        $event,
                                                                        scope.$index,
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .sysProductId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "sysProductId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.sysProductId",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.productList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.id,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3538502317
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Nominated Qty"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesProducts.${scope.$index}.finalQuantity`,
                                                              rules:
                                                                _vm.rules
                                                                  .finalQtyRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 3,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleFinalQtyChange(
                                                                        scope.$index,
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .finalQuantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "finalQuantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.finalQuantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2900307631
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "200px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Specs"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesProducts.${scope.$index}.sysProductSpecificationId`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqRule
                                                                  : _vm.rules
                                                                      .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  "visible-change":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductSpecsVisibleChange(
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleProductSpecsChange(
                                                                        $event,
                                                                        scope.$index,
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .sysProductSpecificationId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "sysProductSpecificationId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.sysProductSpecificationId",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.productSpecificationList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.name,
                                                                        value:
                                                                          item.id,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                875048671
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Min Qty"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesProducts.${scope.$index}.minimumQuantity`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqmMinQtyRule
                                                                  : _vm.rules
                                                                      .minQtyRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 3,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleMinQtyChange(
                                                                        scope.$index,
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .minimumQuantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "minimumQuantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.minimumQuantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3001029691
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Max Qty"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesProducts.${scope.$index}.maximumQuantity`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqMaxQtyRule
                                                                  : _vm.rules
                                                                      .maxQtyRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 3,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleMaxQtyChange(
                                                                        scope.$index,
                                                                        "SELLER"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .maximumQuantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "maximumQuantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.maximumQuantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2129231350
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "120px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        !_vm.isShowVictoryFeats
                                                          ? _c("span", [
                                                              _vm._v("* "),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v("Unit Metric"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesProducts.${scope.$index}.unitMetric`,
                                                              rules:
                                                                _vm.isShowVictoryFeats
                                                                  ? _vm.rules
                                                                      .notReqRule
                                                                  : _vm.rules
                                                                      .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ) ||
                                                                    !_vm.isShowVictoryFeats,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .unitMetric,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "unitMetric",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.unitMetric",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.UNIT_OPTIONS,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.value,
                                                                      attrs: {
                                                                        label:
                                                                          item.label,
                                                                        value:
                                                                          item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2531138240
                                              ),
                                            }),
                                            !(
                                              _vm.linkedSalesTermContract &&
                                              _vm.linkedSalesTermContract
                                                .termContractType ===
                                                "TIME_CHARTER"
                                            ) && !_vm.isOps
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    "min-width": "150px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v("* "),
                                                            ]),
                                                            _vm._v(
                                                              "Price (MT)"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  prop: `salesProducts.${scope.$index}.price`,
                                                                  rules:
                                                                    _vm.rules
                                                                      .reqRule,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    attrs: {
                                                                      controls: false,
                                                                      precision: 3,
                                                                      min: null,
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                          _vm
                                                                            .ORDER_VIEW
                                                                            .VIEW_ORDER ||
                                                                        !(
                                                                          scope
                                                                            .row
                                                                            .status ===
                                                                            "PENDING" ||
                                                                          !scope
                                                                            .row
                                                                            .status
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .price,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "price",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.price",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    77242916
                                                  ),
                                                })
                                              : _vm._e(),
                                            !_vm.isOps
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "Currency",
                                                    "min-width": "120px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .currency,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "currency",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.currency",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.currencyList,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.id,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.code,
                                                                              value:
                                                                                item.code,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2359304257
                                                  ),
                                                })
                                              : _vm._e(),
                                            _vm.orderForm.orderType !==
                                            _vm.ORDER_TYPE.EX_WHARF
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    "min-width": "250px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            !_vm.isShowVictoryFeats
                                                              ? _c("span", [
                                                                  _vm._v("* "),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "Physical Supplier BDN"
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  prop: `salesProducts.${scope.$index}.physicalSupplierSysOrganizationId`,
                                                                  rules:
                                                                    _vm.isShowVictoryFeats
                                                                      ? _vm
                                                                          .rules
                                                                          .notReqRule
                                                                      : _vm
                                                                          .rules
                                                                          .reqRule,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      filterable:
                                                                        "",
                                                                      "value-key":
                                                                        "id",
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                          _vm
                                                                            .ORDER_VIEW
                                                                            .VIEW_ORDER ||
                                                                        !(
                                                                          scope
                                                                            .row
                                                                            .status ===
                                                                            "PENDING" ||
                                                                          !scope
                                                                            .row
                                                                            .status
                                                                        ) ||
                                                                        _vm.productsVerifiedList
                                                                          .filter(
                                                                            (
                                                                              p
                                                                            ) =>
                                                                              p.sysProductId ===
                                                                              scope
                                                                                .row
                                                                                .sysProductId
                                                                          )
                                                                          .filter(
                                                                            (
                                                                              p
                                                                            ) =>
                                                                              p.sysProductSpecificationId ===
                                                                              scope
                                                                                .row
                                                                                .sysProductSpecificationId
                                                                          )
                                                                          .length >
                                                                          0,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePhysicalSupplierBdnChange(
                                                                            $event,
                                                                            scope.$index,
                                                                            "SELLER"
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .physicalSupplierSysOrganizationId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "physicalSupplierSysOrganizationId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.physicalSupplierSysOrganizationId",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.physicalSupplierList,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.id,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.companyName,
                                                                              value:
                                                                                item.id,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    830304595
                                                  ),
                                                })
                                              : _vm._e(),
                                            _vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE
                                                .BARGING_NOMINATION &&
                                            !_vm.isShowVictoryFeats
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "Price Type",
                                                    "min-width": "120px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                          _vm
                                                                            .ORDER_VIEW
                                                                            .VIEW_ORDER ||
                                                                        !(
                                                                          scope
                                                                            .row
                                                                            .status ===
                                                                            "PENDING" ||
                                                                          !scope
                                                                            .row
                                                                            .status
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePriceTypeSelect(
                                                                            $event,
                                                                            scope.row,
                                                                            "SELLER",
                                                                            scope.$index
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .priceType,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "priceType",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.priceType",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.ORDER_PRICE_TYPE_OPTIONS,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.value,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.label,
                                                                              value:
                                                                                item.value,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3198656660
                                                  ),
                                                })
                                              : _vm._e(),
                                            !_vm.isOps &&
                                            _vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE.BARGING_NOMINATION
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    width:
                                                      _vm.orderForm
                                                        .orderType ===
                                                        _vm.ORDER_TYPE
                                                          .EX_WHARF &&
                                                      _vm.currentView !==
                                                        _vm.ORDER_VIEW
                                                          .CREATE_ORDER
                                                        ? "240px"
                                                        : "auto",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.orderForm.orderType ===
                                              _vm.ORDER_TYPE.DELIVERED ||
                                            (_vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE.DELIVERED &&
                                              _vm.orderForm.salesProducts[0]
                                                .status &&
                                              _vm.orderForm.salesProducts[0]
                                                .status !== "PENDING")
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    label: "",
                                                    width: "50px",
                                                    fixed: "right",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              [
                                                                scope.row
                                                                  .status ===
                                                                  "PENDING" ||
                                                                !scope.row
                                                                  .status
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "danger",
                                                                          icon: "el-icon-delete",
                                                                          size: "mini",
                                                                          circle:
                                                                            "",
                                                                          disabled:
                                                                            _vm.currentView ===
                                                                              _vm
                                                                                .ORDER_VIEW
                                                                                .VIEW_ORDER ||
                                                                            _vm
                                                                              .orderForm
                                                                              .salesProducts
                                                                              .length ===
                                                                              1,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAlert(
                                                                                true,
                                                                                scope.row,
                                                                                scope.$index,
                                                                                "SELLER"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "el-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "item",
                                                                        attrs: {
                                                                          effect:
                                                                            "dark",
                                                                          content:
                                                                            "Verified",
                                                                          placement:
                                                                            "top",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-check",
                                                                            attrs:
                                                                              {
                                                                                type: "success",
                                                                                size: "mini",
                                                                                circle:
                                                                                  "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3588686758
                                                  ),
                                                })
                                              : _vm._e(),
                                            !_vm.isOps &&
                                            _vm.orderForm.orderType !==
                                              _vm.ORDER_TYPE.BARGING_NOMINATION
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    type: "expand",
                                                    "min-width": "50px",
                                                    width: "auto",
                                                    fixed: "right",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "table-col-product-pricing",
                                                              {
                                                                attrs: {
                                                                  parent:
                                                                    "OrderFormSalesProduct",
                                                                  nominationDate:
                                                                    new Date(
                                                                      _vm.orderForm.nominationDate
                                                                    ),
                                                                  scopeRow:
                                                                    scope.row,
                                                                  scopeIndex:
                                                                    scope.$index,
                                                                  priceProp:
                                                                    "salesProducts",
                                                                  priceType:
                                                                    scope.row
                                                                      .priceType,
                                                                  priceMethodologyList:
                                                                    _vm.priceMethodologyList,
                                                                  rulesReq:
                                                                    _vm.rules
                                                                      .reqRule,
                                                                  notReqRule:
                                                                    _vm.rules
                                                                      .notReqRule,
                                                                  holidayMap:
                                                                    _vm.holidayMap,
                                                                  resetProductPriceWatcher:
                                                                    _vm.resetProductPriceWatcher,
                                                                  isAllFieldsDisabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "PENDING" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  handlePriceInfoChange:
                                                                    _vm.handlePriceInfoChange,
                                                                  handleReferencePriceChange:
                                                                    _vm.handleReferencePriceChange,
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2195621811
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.isOps
                                  ? _c("div", [
                                      _c("h1", [
                                        _vm._v(
                                          "Remarks: " +
                                            _vm._s(_vm.orderForm.remark || "-")
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      [
                        !_vm.isVictoryFeatsCusPO
                          ? _c(
                              "div",
                              { staticClass: "basic-card-block margin-top-0" },
                              [
                                _c("h1", [_vm._v("Basic Information")]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          "div-frac-13-avg":
                                            !_vm.isMobileScreen,
                                          "div-frac-12-avg": _vm.isMobileScreen,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Mitigating Company",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.orderForm
                                                    .mitigatingCompany,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "mitigatingCompany",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderForm.mitigatingCompany",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.isOnline
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Name",
                                                  prop: "name",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      disabled:
                                                        _vm.currentView ===
                                                          _vm.ORDER_VIEW
                                                            .VIEW_ORDER ||
                                                        _vm.currentView ===
                                                          _vm.ORDER_VIEW
                                                            .EDIT_ORDER,
                                                    },
                                                    model: {
                                                      value: _vm.orderForm.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderForm,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderForm.name",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.currentCompanyUserList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Name",
                                                  prop: "name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: _vm.orderForm.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Order Date",
                                              prop: "orderDate",
                                              rules: _vm.rules.reqRule,
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                type: "datetime",
                                                format:
                                                  _vm.DATETIME_FORMAT
                                                    .GEO_DATETIME,
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.orderForm.nominationDate =
                                                    null
                                                },
                                              },
                                              model: {
                                                value: _vm.orderForm.orderDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "orderDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderForm.orderDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Nomination Date",
                                              prop: "nominationDate",
                                              rules:
                                                _vm.rules
                                                  .validateNominationDate,
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                type: "datetime",
                                                format:
                                                  _vm.DATETIME_FORMAT
                                                    .GEO_DATETIME,
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                                "picker-options":
                                                  _vm.nominationDatePickerOption(),
                                              },
                                              on: {
                                                change:
                                                  _vm.handleNominationDateChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.orderForm.nominationDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "nominationDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderForm.nominationDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                        _vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE
                                            .PURCHASE_AND_SALES
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    "Purchase Reference No.",
                                                  prop: "buyerReferenceNo",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.currentView ===
                                                      _vm.ORDER_VIEW.VIEW_ORDER,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .buyerReferenceNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "buyerReferenceNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.buyerReferenceNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE.SALES ||
                                        _vm.tradeType ===
                                          _vm.ORDER_TRADE_TYPE
                                            .PURCHASE_AND_SALES
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Sales Reference No.",
                                                  prop: "sellerReferenceNo",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.currentView ===
                                                      _vm.ORDER_VIEW.VIEW_ORDER,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .sellerReferenceNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "sellerReferenceNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.sellerReferenceNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.isSpot &&
                                    _vm.tradeType !== _vm.ORDER_TRADE_TYPE.SALES
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "btn-link-to-term",
                                            attrs: {
                                              round: "",
                                              outline: "",
                                              size: "small",
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            on: {
                                              click: function ($event) {
                                                ;(_vm.isLinkedSalesTermContract = false),
                                                  (_vm.dialogLinkTermContract = true)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Link to Term " +
                                                _vm._s(
                                                  _vm.tradeType ===
                                                    _vm.ORDER_TRADE_TYPE
                                                      .PURCHASE_AND_SALES
                                                    ? "(Purchase Order)"
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isSpot &&
                                    _vm.tradeType !== _vm.ORDER_TRADE_TYPE.SALES
                                      ? _c("table-term-contract", {
                                          attrs: {
                                            hasRightMenu: "",
                                            isRightMenuDisabled:
                                              _vm.currentView ===
                                              _vm.ORDER_VIEW.VIEW_ORDER,
                                            orderType: _vm.orderForm.orderType,
                                            orderTableData:
                                              _vm.linkedPurchaseTermContract
                                                ? [
                                                    _vm.linkedPurchaseTermContract,
                                                  ]
                                                : [],
                                            parent: "LinkedPurchaseOrder",
                                          },
                                          on: {
                                            handleOrderTableRightMenuClick:
                                              _vm.handleOrderTableRightMenuClick,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isSpot &&
                                    _vm.tradeType !==
                                      _vm.ORDER_TRADE_TYPE.PURCHASE
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "btn-link-to-term",
                                            attrs: {
                                              round: "",
                                              outline: "",
                                              size: "small",
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.isLinkedSalesTermContract = true
                                                _vm.dialogLinkTermContract = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Link to Term " +
                                                _vm._s(
                                                  _vm.tradeType ===
                                                    _vm.ORDER_TRADE_TYPE
                                                      .PURCHASE_AND_SALES
                                                    ? "(Sales Order)"
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isSpot &&
                                    _vm.tradeType !==
                                      _vm.ORDER_TRADE_TYPE.PURCHASE
                                      ? _c("table-term-contract", {
                                          attrs: {
                                            hasRightMenu: "",
                                            isRightMenuDisabled:
                                              _vm.currentView ===
                                              _vm.ORDER_VIEW.VIEW_ORDER,
                                            orderType: _vm.orderForm.orderType,
                                            orderTableData:
                                              _vm.linkedSalesTermContract
                                                ? [_vm.linkedSalesTermContract]
                                                : [],
                                            parent: "LinkedSalesOrder",
                                          },
                                          on: {
                                            handleOrderTableRightMenuClick:
                                              _vm.handleOrderTableRightMenuClick,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isVictoryFeatsCusPO
                          ? _c("div", { staticClass: "basic-card-block" }, [
                              _c("h1", [
                                _vm._v(
                                  _vm._s(
                                    _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                                      ? "Buyer"
                                      : _vm.tradeType ===
                                        _vm.ORDER_TRADE_TYPE.PURCHASE
                                      ? "Seller"
                                      : "Buyer and Seller"
                                  ) + " Information"
                                ),
                              ]),
                              _c("div", [
                                _vm.tradeType ===
                                _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                  ? _c("h4", [_vm._v("Seller Information")])
                                  : _vm._e(),
                                _c("div", [
                                  _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                  _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                    ? _c(
                                        "div",
                                        { staticClass: "div-checkbox" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              model: {
                                                value:
                                                  _vm.isSellerGreenlightSupply,
                                                callback: function ($$v) {
                                                  _vm.isSellerGreenlightSupply =
                                                    $$v
                                                },
                                                expression:
                                                  "isSellerGreenlightSupply",
                                              },
                                            },
                                            [_vm._v("Green Light Supply")]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                            },
                                            [_vm._v("GST Applicable")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _vm.tradeType ===
                                  _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                _vm.tradeType ===
                                  _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "div-frac-13-avg":
                                            !_vm.isMobileScreen,
                                          "div-frac-1": _vm.isMobileScreen,
                                        },
                                      },
                                      [
                                        _vm.isOnline
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Company Name",
                                                  prop: "sellerSysOrganizationId",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      "value-key": "id",
                                                      disabled:
                                                        _vm.currentView ===
                                                          _vm.ORDER_VIEW
                                                            .VIEW_ORDER ||
                                                        _vm.currentView ===
                                                          _vm.ORDER_VIEW
                                                            .EDIT_ORDER ||
                                                        _vm.linkedPurchaseTermContract !==
                                                          null ||
                                                        _vm.tradeType ===
                                                          _vm.ORDER_TRADE_TYPE
                                                            .PURCHASE,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleCompanyNameSelect(
                                                          $event,
                                                          "SELLER"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderForm
                                                          .sellerSysOrganizationId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderForm,
                                                          "sellerSysOrganizationId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderForm.sellerSysOrganizationId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.creditAssociationList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label:
                                                            item.counterpartyCompanyName,
                                                          value:
                                                            item.counterpartySysOrganizationId,
                                                          disabled:
                                                            item.status !==
                                                            "ACTIVE",
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Company Name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .sellerCompanyName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "sellerCompanyName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.sellerCompanyName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _vm.isOnline
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "In-Charge",
                                                  prop: "sellerId",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      "value-key": "id",
                                                      disabled:
                                                        _vm.currentView ===
                                                        _vm.ORDER_VIEW
                                                          .VIEW_ORDER,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleBuyerSellerNameSelect(
                                                          $event,
                                                          "SELLER"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderForm.sellerId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderForm,
                                                          "sellerId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderForm.sellerId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.sortByAlphabetical(
                                                      _vm.sellerCustomerList,
                                                      "name"
                                                    ),
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                          disabled:
                                                            !item.isActive &&
                                                            !_vm
                                                              .creditAssociation
                                                              .isOnlineCounterparty,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              { attrs: { label: "In-Charge" } },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderForm.sellerName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "sellerName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.sellerName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Email Address",
                                              prop: "sellerEmail",
                                              rules: _vm.GENERAL_EMAIL_RULE_REQ,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              model: {
                                                value:
                                                  _vm.orderForm.sellerEmail,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "sellerEmail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderForm.sellerEmail",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (_vm.tradeType ===
                                  _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                  _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES) &&
                                !(
                                  _vm.linkedPurchaseTermContract &&
                                  _vm.linkedPurchaseTermContract
                                    .termContractType === "TIME_CHARTER"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "div-frac-13-avg":
                                            !_vm.isMobileScreen,
                                          "div-frac-12-avg": _vm.isMobileScreen,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Payment Terms",
                                              prop: "sellerPaymentTerm",
                                              rules: _vm.rules.reqRule,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.currentView ===
                                                    _vm.ORDER_VIEW.VIEW_ORDER,
                                                },
                                                on: {
                                                  change: (e) =>
                                                    _vm.handlePaymentTermChange(
                                                      e,
                                                      false
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm
                                                      .sellerPaymentTerm,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "sellerPaymentTerm",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.sellerPaymentTerm",
                                                },
                                              },
                                              _vm._l(
                                                _vm.PAYMENT_TERM_OPTIONS,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.orderForm.sellerPaymentTerm !==
                                        "SPECIFIC_DATE"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Credit Days",
                                                  prop: "sellerCreditDay",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    controls: false,
                                                    precision: 0,
                                                    min: 0,
                                                    disabled:
                                                      _vm.currentView ===
                                                        _vm.ORDER_VIEW
                                                          .VIEW_ORDER ||
                                                      _vm.orderForm
                                                        .sellerPaymentTerm ===
                                                        "CASH_IN_ADVANCE",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .sellerCreditDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "sellerCreditDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.sellerCreditDay",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Payment Term Date",
                                                  prop: "sellerPaymentTermDate",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.currentView ===
                                                      _vm.ORDER_VIEW.VIEW_ORDER,
                                                    type: "date",
                                                    format: "dd-MM-yyyy",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .sellerPaymentTermDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "sellerPaymentTermDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.sellerPaymentTermDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.tradeType ===
                                _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                  ? _c("h4", [_vm._v("Buyer Information")])
                                  : _vm._e(),
                                _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES ||
                                _vm.tradeType ===
                                  _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "div-checkbox" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              model: {
                                                value:
                                                  _vm.isBuyerGreenlightSupply,
                                                callback: function ($$v) {
                                                  _vm.isBuyerGreenlightSupply =
                                                    $$v
                                                },
                                                expression:
                                                  "isBuyerGreenlightSupply",
                                              },
                                            },
                                            [_vm._v("Green Light Supply")]
                                          ),
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                            },
                                            [_vm._v("GST Applicable")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES ||
                                _vm.tradeType ===
                                  _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "div-frac-13-avg":
                                            !_vm.isMobileScreen,
                                          "div-frac-1": _vm.isMobileScreen,
                                        },
                                      },
                                      [
                                        _vm.isOnline
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Company Name",
                                                  prop: "buyerSysOrganizationId",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      "value-key": "id",
                                                      disabled:
                                                        _vm.currentView ===
                                                          _vm.ORDER_VIEW
                                                            .VIEW_ORDER ||
                                                        _vm.currentView ===
                                                          _vm.ORDER_VIEW
                                                            .EDIT_ORDER ||
                                                        _vm.linkedSalesTermContract !==
                                                          null,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleCompanyNameSelect(
                                                          $event,
                                                          "BUYER"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderForm
                                                          .buyerSysOrganizationId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderForm,
                                                          "buyerSysOrganizationId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderForm.buyerSysOrganizationId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.creditAssociationList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label:
                                                            item.counterpartyCompanyName,
                                                          value:
                                                            item.counterpartySysOrganizationId,
                                                          disabled:
                                                            item.status !==
                                                            "ACTIVE",
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Company Name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .buyerCompanyName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "buyerCompanyName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.buyerCompanyName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _vm.isOnline
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "In-Charge",
                                                  prop: "buyerId",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      "value-key": "id",
                                                      disabled:
                                                        _vm.currentView ===
                                                        _vm.ORDER_VIEW
                                                          .VIEW_ORDER,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleBuyerSellerNameSelect(
                                                          $event,
                                                          "BUYER"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orderForm.buyerId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orderForm,
                                                          "buyerId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orderForm.buyerId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.sortByAlphabetical(
                                                      _vm.buyerCustomerList,
                                                      "name"
                                                    ),
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                          disabled:
                                                            !item.isActive &&
                                                            !_vm
                                                              .creditAssociation
                                                              .isOnlineCounterparty,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              { attrs: { label: "In-Charge" } },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.orderForm.buyerName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "buyerName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.buyerName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Email Address",
                                              prop: "buyerEmail",
                                              rules: _vm.GENERAL_EMAIL_RULE_REQ,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              model: {
                                                value: _vm.orderForm.buyerEmail,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "buyerEmail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orderForm.buyerEmail",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (_vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES ||
                                  _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES) &&
                                !(
                                  _vm.linkedSalesTermContract &&
                                  _vm.linkedSalesTermContract
                                    .termContractType === "TIME_CHARTER"
                                )
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "div-frac-13-13-16-16":
                                            !_vm.isMobileScreen,
                                          "div-frac-12-avg": _vm.isMobileScreen,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Payment Terms",
                                              prop: "buyerPaymentTerm",
                                              rules: _vm.rules.reqRule,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.currentView ===
                                                    _vm.ORDER_VIEW.VIEW_ORDER,
                                                },
                                                on: {
                                                  change: (e) =>
                                                    _vm.handlePaymentTermChange(
                                                      e,
                                                      true
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.orderForm
                                                      .buyerPaymentTerm,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "buyerPaymentTerm",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.buyerPaymentTerm",
                                                },
                                              },
                                              _vm._l(
                                                _vm.PAYMENT_TERM_OPTIONS,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.orderForm.buyerPaymentTerm !==
                                        "SPECIFIC_DATE"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Credit Days",
                                                  prop: "buyerCreditDay",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    controls: false,
                                                    precision: 0,
                                                    min: 0,
                                                    disabled:
                                                      _vm.currentView ===
                                                        _vm.ORDER_VIEW
                                                          .VIEW_ORDER ||
                                                      _vm.orderForm
                                                        .buyerPaymentTerm ===
                                                        "CASH_IN_ADVANCE",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .buyerCreditDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "buyerCreditDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.buyerCreditDay",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "Payment Term Date",
                                                  prop: "buyerPaymentTermDate",
                                                  rules: _vm.rules.reqRule,
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.currentView ===
                                                      _vm.ORDER_VIEW.VIEW_ORDER,
                                                    type: "date",
                                                    format: "dd-MM-yyyy",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orderForm
                                                        .buyerPaymentTermDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orderForm,
                                                        "buyerPaymentTermDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orderForm.buyerPaymentTermDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "basic-card-block" }, [
                          _c("h1", [
                            _vm._v(
                              _vm._s(
                                _vm.orderForm.orderType ===
                                  _vm.ORDER_TYPE.EX_WHARF
                                  ? ""
                                  : "Vessel "
                              ) + "Lifting Information"
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _vm.orderForm.orderType ===
                              _vm.ORDER_TYPE.EX_WHARF
                                ? [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          "div-frac-13": !_vm.isMobileScreen,
                                          "div-frac-12-avg": _vm.isMobileScreen,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Lifting",
                                              prop: "lifting",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.currentView ===
                                                  _vm.ORDER_VIEW.VIEW_ORDER,
                                              },
                                              model: {
                                                value: _vm.orderForm.lifting,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orderForm,
                                                    "lifting",
                                                    $$v
                                                  )
                                                },
                                                expression: "orderForm.lifting",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Terminal",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  filterable: "",
                                                  disabled:
                                                    _vm.currentView ===
                                                    _vm.ORDER_VIEW.VIEW_ORDER,
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.orderForm.terminal,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.orderForm,
                                                      "terminal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "orderForm.terminal",
                                                },
                                              },
                                              _vm._l(
                                                _vm.locationList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.name,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm.isDeliveryOrBargingNominationJob
                                ? _c("search-and-create-vessel-block", {
                                    attrs: {
                                      disabled: !_vm.canEditVictoryFields,
                                      isVesselTBC: _vm.isVesselTBC,
                                      currentView: _vm.currentView,
                                      searchedVesselList:
                                        _vm.searchedVesselList,
                                      orderForm: _vm.orderForm,
                                      rules: _vm.rules,
                                    },
                                    on: {
                                      handleSearchMpaReceivingVessels:
                                        _vm.handleSearchMpaReceivingVessels,
                                      searchVesselvisibleChange:
                                        _vm.searchVesselvisibleChange,
                                      handleSelectVessel:
                                        _vm.handleSelectVessel,
                                    },
                                  })
                                : _vm._e(),
                              !_vm.isVictoryFeatsCusPO
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        "div-frac-13-avg": !_vm.isMobileScreen,
                                        "div-frac-1": _vm.isMobileScreen,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Date Range",
                                            prop: "dateRange",
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "daterange",
                                              format: "dd-MM-yyyy",
                                              "start-placeholder":
                                                "Stem Start Date",
                                              "end-placeholder":
                                                "Stem End Date",
                                              "picker-options":
                                                _vm.orderStemDateRangePickerOptions(),
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value: _vm.orderForm.dateRange,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm,
                                                  "dateRange",
                                                  $$v
                                                )
                                              },
                                              expression: "orderForm.dateRange",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Port",
                                            prop: "port",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value: _vm.orderForm.port,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm,
                                                  "port",
                                                  $$v
                                                )
                                              },
                                              expression: "orderForm.port",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Terms & Conditions",
                                            prop: "termsAndConditions",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm
                                                  .termsAndConditions,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm,
                                                  "termsAndConditions",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.termsAndConditions",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm.orderForm.orderType !== _vm.ORDER_TYPE.EX_WHARF &&
                        _vm.orderForm.thirdParties.length > 0
                          ? _c("div", { staticClass: "basic-card-block" }, [
                              _c("h1", [_vm._v("Third Party Information")]),
                              _c(
                                "div",
                                { staticClass: "div-third-party-info-body" },
                                [
                                  _c("p", [_vm._v("Agent")]),
                                  _c("hr"),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "div-frac-14": !_vm.isMobileScreen,
                                        "div-frac-12-avg": _vm.isMobileScreen,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Company" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[0]
                                                  .company,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[0],
                                                  "company",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[0].company",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Email",
                                            prop: "thirdParties.0.email",
                                            rules: _vm.GENERAL_EMAIL_RULE,
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[0]
                                                  .email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[0],
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[0].email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "Person-In-Charge" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[0]
                                                  .personInCharge,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[0],
                                                  "personInCharge",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[0].personInCharge",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Contact No.",
                                            prop: "thirdParties.0.contact",
                                            rules: _vm.rules.validatePhone,
                                          },
                                        },
                                        [
                                          _c("phone-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                              size: "samll",
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[0]
                                                  .contact,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[0],
                                                  "contact",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[0].contact",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("p", [_vm._v("Surveyor")]),
                                  _c("hr"),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "div-frac-14": !_vm.isMobileScreen,
                                        "div-frac-12-avg": _vm.isMobileScreen,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Company" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[1]
                                                  .company,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[1],
                                                  "company",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[1].company",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Email",
                                            prop: "thirdParties.1.email",
                                            rules: _vm.GENERAL_EMAIL_RULE,
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[1]
                                                  .email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[1],
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[1].email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "Person-In-Charge" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[1]
                                                  .personInCharge,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[1],
                                                  "personInCharge",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[1].personInCharge",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Contact No.",
                                            prop: "thirdParties.1.contact",
                                            rules: _vm.rules.validatePhone,
                                          },
                                        },
                                        [
                                          _c("phone-input", {
                                            attrs: {
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            model: {
                                              value:
                                                _vm.orderForm.thirdParties[1]
                                                  .contact,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.orderForm.thirdParties[1],
                                                  "contact",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "orderForm.thirdParties[1].contact",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isOps
                          ? _c("div", { staticClass: "basic-card-block" }, [
                              _c("h1", [_vm._v("Other Costs")]),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-product-other-costs-info-body",
                                  },
                                  [
                                    _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                      ? _c("h4", [
                                          _vm._v("Purchase Other Costs"),
                                        ])
                                      : _vm._e(),
                                    (_vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                      _vm.tradeType ===
                                        _vm.ORDER_TRADE_TYPE
                                          .PURCHASE_AND_SALES) &&
                                    !_vm.isViewOnly
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              round: "",
                                              size: "mini",
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addOtherCost("BUYER")
                                              },
                                            },
                                          },
                                          [_vm._v(" Add ")]
                                        )
                                      : _vm._e(),
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE ||
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                      ? _c(
                                          "el-table",
                                          {
                                            attrs: {
                                              data: _vm.orderForm
                                                .purchaseOtherCosts,
                                              border: "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "No.",
                                                width: "50px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "h5-el-table-col-index",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.$index + 1
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1010444001
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Description"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseOtherCosts.${scope.$index}.description`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                  filterable:
                                                                    "",
                                                                  "allow-create":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.description",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.ORDER_OTHER_COST_DESCRIPTION_OPTIONS,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.value,
                                                                      attrs: {
                                                                        label:
                                                                          item.label,
                                                                        value:
                                                                          item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3260771535
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "200px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _vm._v("Product Name"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseOtherCosts.${scope.$index}.orderProductId`,
                                                              rules:
                                                                _vm.rules
                                                                  .notReqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .orderProductId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "orderProductId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.orderProductId",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.purchaseOtherCostProDropDownList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.displayName,
                                                                        value:
                                                                          item.orderProductId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3587925119
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Quantity"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseOtherCosts.${scope.$index}.quantity`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqGreaterThanZeroRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 2,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.calculateOtherCostAmount(
                                                                      scope.$index,
                                                                      true
                                                                    )
                                                                  },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .quantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "quantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.quantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2032925988
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Unit Price"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseOtherCosts.${scope.$index}.unitPrice`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqGreaterThanZeroRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 2,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.calculateOtherCostAmount(
                                                                      scope.$index,
                                                                      true
                                                                    )
                                                                  },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .unitPrice,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.unitPrice",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2052787796
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Amount"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseOtherCosts.${scope.$index}.cost`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 2,
                                                                  min: null,
                                                                  disabled: true,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .cost,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "cost",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.cost",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3316716569
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Currency"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `purchaseOtherCosts.${scope.$index}.currency`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .currency,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "currency",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.currency",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.currencyList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.code,
                                                                        value:
                                                                          item.code,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                907921077
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "",
                                                width: "50px",
                                                fixed: "right",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            scope.row.status ===
                                                              "CONFIRMED" ||
                                                            !scope.row.status
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      icon: "el-icon-delete",
                                                                      size: "mini",
                                                                      circle:
                                                                        "",
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                        _vm
                                                                          .ORDER_VIEW
                                                                          .VIEW_ORDER,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteAlert(
                                                                            false,
                                                                            scope.row,
                                                                            scope.$index,
                                                                            "BUYER"
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content:
                                                                        "Verified",
                                                                      placement:
                                                                        "top",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon-check",
                                                                        attrs: {
                                                                          type: "success",
                                                                          size: "mini",
                                                                          circle:
                                                                            "",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4179811585
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.tradeType ===
                                    _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                      ? _c("h4", { staticClass: "h4-btm" }, [
                                          _vm._v("Sales Other Costs"),
                                        ])
                                      : _vm._e(),
                                    (_vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.SALES ||
                                      _vm.tradeType ===
                                        _vm.ORDER_TRADE_TYPE
                                          .PURCHASE_AND_SALES) &&
                                    !_vm.isViewOnly
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              round: "",
                                              size: "mini",
                                              disabled:
                                                _vm.currentView ===
                                                _vm.ORDER_VIEW.VIEW_ORDER,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addOtherCost(
                                                  "SELLER"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Add ")]
                                        )
                                      : _vm._e(),
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.SALES ||
                                    _vm.tradeType ===
                                      _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
                                      ? _c(
                                          "el-table",
                                          {
                                            ref: "refTable",
                                            attrs: {
                                              data: _vm.orderForm
                                                .salesOtherCosts,
                                              border: "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "No.",
                                                width: "50px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "h5-el-table-col-index",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.$index + 1
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1010444001
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "150px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Description"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesOtherCosts.${scope.$index}.description`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                  filterable:
                                                                    "",
                                                                  "allow-create":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.description",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.ORDER_OTHER_COST_DESCRIPTION_OPTIONS,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.value,
                                                                      attrs: {
                                                                        label:
                                                                          item.label,
                                                                        value:
                                                                          item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2567807756
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "200px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _vm._v("Product Name"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesOtherCosts.${scope.$index}.orderProductId`,
                                                              rules:
                                                                _vm.rules
                                                                  .notReqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                  filterable:
                                                                    "",
                                                                  clearable: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .orderProductId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "orderProductId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.orderProductId",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.salesOtherCostProDropDownList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.displayName,
                                                                        value:
                                                                          item.orderProductId,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3215185919
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Quantity"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesOtherCosts.${scope.$index}.quantity`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqGreaterThanZeroRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 2,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.calculateOtherCostAmount(
                                                                      scope.$index,
                                                                      false
                                                                    )
                                                                  },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .quantity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "quantity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.quantity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1216704076
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v(" Unit Price"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesOtherCosts.${scope.$index}.unitPrice`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqGreaterThanZeroRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 2,
                                                                  min: null,
                                                                  disabled:
                                                                    _vm.currentView ===
                                                                      _vm
                                                                        .ORDER_VIEW
                                                                        .VIEW_ORDER ||
                                                                    !(
                                                                      scope.row
                                                                        .status ===
                                                                        "CONFIRMED" ||
                                                                      !scope.row
                                                                        .status
                                                                    ),
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.calculateOtherCostAmount(
                                                                      scope.$index,
                                                                      false
                                                                    )
                                                                  },
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .unitPrice,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.unitPrice",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1849642300
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "100px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Amount"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesOtherCosts.${scope.$index}.cost`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqGreaterThanZeroRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  controls: false,
                                                                  precision: 2,
                                                                  min: null,
                                                                  disabled: true,
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .cost,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "cost",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.cost",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                74691097
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: { "min-width": "120px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v("* "),
                                                        ]),
                                                        _vm._v("Currency"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: `salesOtherCosts.${scope.$index}.currency`,
                                                              rules:
                                                                _vm.rules
                                                                  .reqRule,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .currency,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "currency",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.currency",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.currencyList,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.id,
                                                                      attrs: {
                                                                        label:
                                                                          item.code,
                                                                        value:
                                                                          item.code,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                778779894
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "",
                                                width: "50px",
                                                fixed: "right",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            scope.row.status ===
                                                              "CONFIRMED" ||
                                                            !scope.row.status
                                                              ? _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      icon: "el-icon-delete",
                                                                      size: "mini",
                                                                      circle:
                                                                        "",
                                                                      disabled:
                                                                        _vm.currentView ===
                                                                        _vm
                                                                          .ORDER_VIEW
                                                                          .VIEW_ORDER,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteAlert(
                                                                            false,
                                                                            scope.row,
                                                                            scope.$index,
                                                                            "SELLER"
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content:
                                                                        "Verified",
                                                                      placement:
                                                                        "top",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon-check",
                                                                        attrs: {
                                                                          type: "success",
                                                                          size: "mini",
                                                                          circle:
                                                                            "",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3480351257
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.isOps
                          ? _c("div", { staticClass: "basic-card-block" }, [
                              _c("h1", [_vm._v("Remarks")]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "div-remarks-info-body" },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 4,
                                            disabled:
                                              _vm.currentView ===
                                              _vm.ORDER_VIEW.VIEW_ORDER,
                                          },
                                          model: {
                                            value: _vm.orderForm.remark,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orderForm,
                                                "remark",
                                                $$v
                                              )
                                            },
                                            expression: "orderForm.remark",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                    ],
                    2
                  )
                : _c("order-commission-form", {
                    ref: "orderCommission",
                    attrs: {
                      currencyList: _vm.currencyList,
                      creditAssociationList: _vm.creditAssociationList,
                      productList: _vm.productList,
                      tradeType: _vm.tradeType,
                      orderProductList: _vm.orderForm.products,
                      propsView: _vm.currentView,
                      orderCommission: _vm.orderCommission,
                      resetOrderComm: _vm.resetOrderComm,
                    },
                  }),
              _vm.currentView !== _vm.ORDER_VIEW.CREATE_ORDER &&
              _vm.isOrderSideBar
                ? _c("div", { staticClass: "div-form-main-right-summary" }, [
                    _c("div", [
                      _c("div", { staticClass: "div-order-summary-main" }, [
                        _c("h4", [
                          _vm._v(
                            _vm._s(
                              _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                                ? "Sales Order No."
                                : "Purchase Order No."
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "div-order-summary-order-no" },
                          [
                            _c("h1", [
                              _vm._v(
                                _vm._s(
                                  _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                                    ? _vm.orderForm.salesOrderNo
                                    : _vm.orderForm.purchaseOrderNo
                                )
                              ),
                            ]),
                            _vm.hasViewPdfDropdownItem()
                              ? _c("i", {
                                  staticClass: "el-icon-document",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleGenerateOrderPdf()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c("div", [
                          _c("h3", [_vm._v("Order Status")]),
                          _c(
                            "div",
                            {
                              staticClass: "status-tag",
                              style: _vm.getStatusTagStyle(
                                _vm.orderForm.sellerSysOrganizationId ===
                                  _vm.myCompany.id
                                  ? _vm.orderForm.sellerOrderStatus
                                  : _vm.orderForm.buyerOrderStatus
                              ),
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderForm.sellerSysOrganizationId ===
                                    _vm.myCompany.id
                                    ? _vm.capitalizeFirstLetter(
                                        _vm.orderForm.sellerOrderStatus,
                                        "_"
                                      )
                                    : _vm.capitalizeFirstLetter(
                                        _vm.orderForm.buyerOrderStatus,
                                        "_"
                                      )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("dialog-link-term-contract", {
        attrs: {
          buyerSysOrganizationId: _vm.orderForm.buyerSysOrganizationId,
          sellerSysOrganizationId: _vm.orderForm.sellerSysOrganizationId,
          propsView: _vm.currentView,
          isLinkedSalesTermContract: _vm.isLinkedSalesTermContract,
          linkedSalesTermContract: _vm.linkedSalesTermContract,
          linkedPurchaseTermContract: _vm.linkedPurchaseTermContract,
          dialogLinkTermContract: _vm.dialogLinkTermContract,
          orderType: _vm.orderForm.orderType,
        },
        on: {
          handleLinkTermContractDialogClose:
            _vm.handleLinkTermContractDialogClose,
          handleLinkTermContractConfirm: _vm.handleLinkTermContractConfirm,
        },
      }),
      _c("dialog-verify-order", {
        attrs: {
          orderType: _vm.orderType,
          nominationDate: new Date(_vm.orderForm.nominationDate),
          holidayMap: _vm.holidayMap,
          currencyList: _vm.currencyList,
          orderId: _vm.orderId,
          dialogVerifyOrder: _vm.dialogVerifyOrder,
          priceMethodologyList: _vm.priceMethodologyList,
        },
        on: {
          "update:dialogVerifyOrder": function ($event) {
            _vm.dialogVerifyOrder = $event
          },
          "update:dialog-verify-order": function ($event) {
            _vm.dialogVerifyOrder = $event
          },
          handleVerifyOrderDialogConfirm: _vm.handleVerifyOrder,
          handleVerifyOrderDialogClose: function ($event) {
            _vm.dialogVerifyOrder = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }